import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import Table from "../../../../Components/UiComponents/Table/Table";
import { deleteData, deleteDataById, getData, postDataWithBody, postDataWithBodyFrGIS, putDataWithBody } from "../../../../Services/Services";
import { deleteTaskById, getMatserUserTaskList } from "../../../../Services/EndPoints";
import Loader from "react-spinner-loader";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Deleteicon } from "../../../../Components/IconComponents";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";

const UserTaskTable = () => {
  const { t } = useTranslation();
  const [userTaskData, setUserTaskData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isTextFieldDisable, setIsTextFieldDisable] = useState(true); // State to manage isTextFieldDisable
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const addNewHandler = () => {
    navigate("/my-tasks/create-usertask");
  };

  useEffect(() => {
    getTaskList();
  }, []);

  const getTaskList = async () => {
  setLoader(true);
    const response = await getData(
      getMatserUserTaskList,
      "",
      {}
    );
    console.log(response,"response")
    const masterUserTaskList = response || [];
    setLoader(false);
    setUserTaskData(masterUserTaskList);
    setRowCount(masterUserTaskList.length);
  };

  const handleClickView = () => {
    setIsTextFieldDisable(false); // Switch to editable mode when viewing
  };

  console.log(userTaskData,"usertaskdata")
  

  const columns = React.useMemo(
    () => [
      {
        Header: t("dailytaskmgmnttableheaders.name"),
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: t("dailytaskmgmnttableheaders.tasktype"),
        accessor: "task_type",
        disableSortBy: true,
        Cell: (props) => {
          return props.value === 0 ? "Activity" : "Notification";
        },
      },
      {
        Header: t("dailytaskmgmnttableheaders.assigntype"),
        accessor: "assign_type",
        disableSortBy: true,
        Cell: (props) => {
          return props.value === 0 ? "Group" : "Users";
        },
      },
      {
        Header: t("dailytaskmgmnttableheaders.assignto"),
        accessor: "assign_to", // Virtual field to be computed in Cell
        disableSortBy: true,
        Cell: (props) => {
          const { assign_type, user_list, role_list } = props.row.original;
      
          if (assign_type === 0) {
            return role_list && role_list.trim()
              ? role_list
              : t("no_roles_assigned");
          } else if (assign_type === 1) {
            if (user_list && user_list.trim()) {
              const usernames = user_list
                .split(",")
                .map((email) => email.split("@")[0])
                .join(", ");
              return usernames;
            }
            return t("NA"); // Default message if empty
          }
      
          return t("unknown_assign_type"); // Fallback message for invalid assign_type
        },
      },
          
      {
        Header: t("dailytaskmgmnttableheaders.createdon"),
        accessor: "createdOn",
        disableSortBy: true,
        Cell: (props) => (
          <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>
        ),
      },
      // {
      //   Header: t("dailytaskmgmnttableheaders.action"),
      //   disableSortBy: true,
      //   Cell: (props) => (
      //     <div className="usertable__actionsicon">
      //       <span
      //         className="icon__position"
      //         onClick={() => {
      //           navigate("/my-tasks/create-usertask", {
      //             state: {
      //               data: props.row.original,
      //               isTextFieldDisable: isTextFieldDisable,
      //             },
      //           });
      //           handleClickView(); // Update state after navigation
      //         }}
      //       >
      //         <i
      //           className="cursor-pointer fa fa-eye ms-3"
      //           role="button"
      //           value={props.value}
      //         ></i>
      //       </span>
      //     </div>
      //   ),
      // },
      {
        Header: t("dailytaskmgmnttableheaders.action"),
        disableSortBy: true,
        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const [selectedId, setSelectedId] = useState(null); // State to store the ID for deletion
      
          // Function to toggle delete confirmation popup
          const deletePopUp = (id) => {
            setSelectedId(id); // Set the selected ID for deletion
            setIsdelete(!isdelete); // Toggle the popup
          };
      
          // Function to handle deletion of a row
          const deleteTableDataById = async () => {
            try {
              const response = await deleteDataById(deleteTaskById, selectedId); // Use the stored ID
      
              if (response?.status === "success") {
                // Refresh the task list
                await getTaskList();
      
                // Show success message
                toast.success(response.msg || t("delete_success_message"), {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                });
              } else {
                // Handle failure case
                toast.error(response.msg || t("delete_failure_message"), {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                });
              }
            } catch (error) {
              // Handle unexpected errors
              toast.error(t("delete_error_message"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
              console.error("Delete error:", error);
            } finally {
              setIsdelete(false); // Close the delete confirmation popup
              setSelectedId(null); // Clear the selected ID
            }
          };
      
          // Inline styles
          const actionContainerStyle = {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px", // Space between icons
          };
      
          const viewIconStyle = {
            cursor: "pointer",
            fontSize: "13px", // Smaller icon size
            color: "#007bff", // Default view icon color
          };
      
          const deleteIconStyle = {
            cursor: "pointer",
            fontSize: "13px", // Smaller icon size
            color: "#F0F0F7", // Reverted delete icon color
          };
      
          // const iconHoverStyle = {
          //   transform: "scale(1.1)", // Slight zoom effect
          // };
      
          return (
            <div style={actionContainerStyle}>
              {/* View Icon */}
              <span
                title={t("view_task")} // Tooltip for better UX
                onClick={() => {
                  navigate("/my-tasks/create-usertask", {
                    state: {
                      data: props.row.original,
                      isTextFieldDisable: isTextFieldDisable,
                    },
                  });
                  handleClickView(); // Update state after navigation
                }}
              >
                <i
                  className="fa fa-eye"
                  style={viewIconStyle}
                ></i>
              </span>
      
              {/* Delete Icon */}
              <span
                title={t("delete_task")} // Tooltip for better UX
                onClick={() => deletePopUp(props.row.original.id)}
              >
                <Deleteicon
                  style={deleteIconStyle}
                />
              </span>
      
              {/* Delete Confirmation Popup */}
              {isdelete && (
                <Confirmpopup
                  className="DeletePopup"
                  show={isdelete}
                  title={t("deletepopuptext")} // Popup title
                  message={t("deletepopupmessage")} // Popup message
                  handleToggle={() => deletePopUp(null)} // Function to close the popup
                  deleteRow={deleteTableDataById} // Function to confirm deletion
                  cancel={t("cancelbutton")} // Cancel button text
                  action={t("actionbutton")} // Action button text
                />
              )}
            </div>
          );
        },
      }
      
      
      
      
    ],
    [t, isTextFieldDisable, navigate]
  );

  return (
    <div>
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="container create-user">
        <div className="datatable__container">
          <Table
            data={userTaskData}
            addButtonLabel={t("dailytaskaddbuttonlabel")}
            addNewHandler={addNewHandler}
            columns={columns}
            numberOfRows={7}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={rowCount}
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid  #f0f0f0",
              padding: "2px",
              borderRadius: "12px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserTaskTable;
